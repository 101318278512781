@import "./../../variable/variable.scss";

.nav-link {
  font-family: $OswaldFont;
  color: #fff;
  text-decoration: none;
  margin: 0 10px;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  transition: 0.3s;
  // opacity: 50%;
}

.nav-link.active {
  padding-bottom: 12px;
  border-bottom: 3px solid $DarkBlue;
  transition: 0.3s;
}

.nav-link:hover {
  padding-bottom: 12px;
  border-bottom: 3px solid $DarkBlue;
  transition: 0.3s;
}

.nav-links {
  font-family: $OswaldFont;
  color: #000;
  text-decoration: none;
  margin: 0 10px;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
  display: block;
  margin: 20px 0;
  transition: 0.3s;
  // background-color: red;
}
