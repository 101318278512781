/* Poppin */
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quattrocento:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
.footerContainer {
  background: linear-gradient(45deg, #565555 0.13%, rgba(34, 34, 34, 0.8) 100%);
  padding: 100px 100px;
}

.footerText h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #fff;
  font-weight: 600px;
}

.footerText p {
  font-family: "Montserrat", serif;
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  padding-right: 5px;
}

.phone {
  font-family: "Montserrat", sans-serif;
  color: #007fff;
  text-decoration: none;
  font-weight: bold;
}

.email {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footerMap {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

@media screen and (max-width: 600px) {
  .footerContainer {
    padding: 15px 10px;
  }
  .footerText h1 {
    font-size: 24px;
  }
  .footerText p {
    font-size: 18px;
    margin: 0;
  }
  .phone {
    font-size: 24px;
  }
  .icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .contactInfo {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    padding-right: 20px;
  }
}/*# sourceMappingURL=footer.css.map */