/* Poppin */
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quattrocento:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
.whocontainer {
  background: #222222;
  padding: 50px 100px 50px 100px;
}

.whoheader {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #fff;
  font-size: 64px;
  font-weight: 600;
}

.whocontent {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #fff;
  font-family: "Montserrat", serif;
  font-size: 24px;
  line-height: 2;
}

.title {
  display: flex;
  align-items: center;
}

.mission {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #fff;
  color: #0077ff;
  font-size: 32px;
  margin-left: 32px;
}

.missionPhotoContainer {
  position: relative;
  height: 200px;
  z-index: 1;
}

.missionbackground {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.missionPhoto {
  position: absolute;
  z-index: 5;
  right: 0px;
}

.missionPhoto img {
  width: inherit;
  height: inherit;
}

@media screen and (max-width: 600px) {
  .whocontainer {
    padding: 10px;
  }
  .whoheader {
    font-size: 32px;
    margin-bottom: 50px;
  }
  .whocontent {
    font-size: 18px;
  }
  .missionPhotoContainer {
    width: 100%;
    height: 200px;
    position: static;
  }
  .missionPhotoContainer img {
    width: inherit;
    height: inherit;
    position: static;
  }
}/*# sourceMappingURL=whoarewe.css.map */