@import "./../../variable/variable.scss";

.serviceContainer {
  background-color: $BackgroudColor;
  // background-color: red;
  // height: 100vh;
  padding: 50px 100px 0px 100px;
}

.serviceHeader {
  @include FontStyle;
  font-size: 64px;
}

.serviceContent {
  @include FontStyle;
  font-size: 24px;
}

.serviceBox {
  background-color: #fff;
  padding: 36px 100px;
  border-radius: 10px;
  margin-top: 50px;

  span {
    @include FontStyle;
    // @include LightStyle;
    color: $LightBlue;
    font-size: 32px;
    font-weight: bold;
  }

  p {
    font-family: $QuattrocentoFont;
    font-size: 20px;
    font-weight: 700;
    color: #000;
  }

  .serviceImage {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .explore {
    padding: 10px 15px;
    border: none;
    background-color: $LightBlue;
    color: white;
    font-weight: bold;
    font-size: 16px;
    border-radius: 5px;
  }

  .explore:hover {
    background-color: #fff;
    border: 1px solid $LightBlue;
    color: $LightBlue;
    transform: scale(1.02);
  }
}

@media screen and (max-width: 600px) {
  .serviceContainer {
    padding: 50px 10px;
  }

  .serviceHeader {
    font-size: 32px;
  }

  .serviceContent {
    font-size: 18px;
  }

  .serviceBox {
    padding: 36px;
    span {
      font-size: 28px;
    }

    p {
      font-size: 16px;
    }
  }
}
