@import "./../../variable/variable.scss";

.bannerContainer {
  width: 100vw;
  height: 100vh;
  background: $BackgroudColor;
  position: relative;

  display: flex;
  // justify-content: center;
  align-items: center;

  overflow: hidden;
}

.backgroundImage {
  width: 100%;
  height: 100vh;
  background-image: url("./../../assets/image/Polygon.png");

  position: absolute;
  top: 0px;
  left: 30%;
}

.bannerText {
  padding-left: 100px;

  h1 {
    @include FontStyle;
    font-size: 64px;
  }

  .bannerDescription {
    font-size: 24px;
    @include FontStyle;
  }

  .darkblue {
    @include DarkStyle;
  }

  .lightblue {
    @include LightStyle;
  }

  a {
    text-decoration: none;
    @include FontStyle;
    color: #fff;
    font-weight: bold;
    border-radius: 5px;
    background-color: $DarkBlue;
    padding: 10px 24px;
    transition: 0.5s;
  }

  a:hover {
    color: $LightBlue;
    background-color: #fff;
    transition: 0.5s;
  }
}

@media screen and (max-width: 600px) {
  .backgroundImage {
    // top: px;
    left: 0;
  }

  .bannerText {
    padding-left: 10px;
    h1 {
      font-size: 32px;
    }

    .bannerDescription {
      margin-top: 50px;
      margin-bottom: 20px;
      font-size: 16px;
      @include FontStyle;
    }
  }
}
