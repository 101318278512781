@import "./../../variable/variable.scss";

.whocontainer {
  // width: 100%;
  // background-color: red;
  //   height: 100vh;
  background: $BackgroudColor;
  padding: 50px 100px 50px 100px;
  //   overflow-x: hidden;
}

.whoheader {
  @include FontStyle;
  font-size: 64px;
  font-weight: 600;
}

.whocontent {
  @include FontStyleLight;
  font-family: $QuattrocentoFont;
  font-size: 24px;
  line-height: 2;
}

.title {
  display: flex;
  //   justify-content: center;
  align-items: center;
}

.mission {
  // font-weight: 500;
  @include FontStyle;
  @include DarkStyle;
  font-size: 32px;
  margin-left: 32px;
}

.missionPhotoContainer {
  position: relative;
  height: 200px;
  // background-color: red;

  z-index: 1;
}

.missionbackground {
  position: absolute;
  top: 0;
  right: 0;

  z-index: -1;
}

.missionPhoto {
  // width: 100%;
  // height: inherit;

  position: absolute;

  z-index: 5;
  right: 0px;
}

.missionPhoto img {
  width: inherit;
  height: inherit;
}

@media screen and (max-width: 600px) {
  .whocontainer {
    padding: 10px;
  }

  .whoheader {
    font-size: 32px;
    margin-bottom: 50px;
  }

  .whocontent {
    font-size: 18px;
  }

  .missionPhotoContainer {
    width: 100%;
    height: 200px;
    position: static;
  }

  .missionPhotoContainer img {
    width: inherit;
    height: inherit;

    position: static;
  }
}
