/* Poppin */
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quattrocento:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
.faqContainer {
  background-color: #222222;
  padding: 80px 100px 80px 100px;
}

.faqHeader {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #fff;
  font-weight: 600;
  font-size: 64px;
}

.faqText {
  font-family: "Montserrat", serif;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
}

@media screen and (max-width: 600px) {
  .faqContainer {
    padding: 15px 10px;
  }
  .faqHeader {
    font-size: 32px;
  }
  .faqText {
    font-size: 18px;
  }
}/*# sourceMappingURL=faqsection.css.map */