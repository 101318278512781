/* Poppin */
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quattrocento:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
.serviceContainer {
  background-color: #222222;
  padding: 50px 100px 0px 100px;
}

.serviceHeader {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #fff;
  font-size: 64px;
}

.serviceContent {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #fff;
  font-size: 24px;
}

.serviceBox {
  background-color: #fff;
  padding: 36px 100px;
  border-radius: 10px;
  margin-top: 50px;
}
.serviceBox span {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #fff;
  color: #007fff;
  font-size: 32px;
  font-weight: bold;
}
.serviceBox p {
  font-family: "Montserrat", serif;
  font-size: 20px;
  font-weight: 700;
  color: #000;
}
.serviceBox .serviceImage {
  display: flex;
  justify-content: center;
  align-items: center;
}
.serviceBox .explore {
  padding: 10px 15px;
  border: none;
  background-color: #007fff;
  color: white;
  font-weight: bold;
  font-size: 16px;
  border-radius: 5px;
}
.serviceBox .explore:hover {
  background-color: #fff;
  border: 1px solid #007fff;
  color: #007fff;
  transform: scale(1.02);
}

@media screen and (max-width: 600px) {
  .serviceContainer {
    padding: 50px 10px;
  }
  .serviceHeader {
    font-size: 32px;
  }
  .serviceContent {
    font-size: 18px;
  }
  .serviceBox {
    padding: 36px;
  }
  .serviceBox span {
    font-size: 28px;
  }
  .serviceBox p {
    font-size: 16px;
  }
}/*# sourceMappingURL=serviceweprovide.css.map */