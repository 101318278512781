/* Poppin */
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quattrocento:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
.bannerContainer {
  width: 100vw;
  height: 100vh;
  background: #222222;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.backgroundImage {
  background-image: url("./../../assets/image/Polygon.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.bannerText {
  padding-left: 100px;
}
.bannerText h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #fff;
  font-size: 64px;
}
.bannerText .bannerDescription {
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #fff;
}
.bannerText .darkblue {
  color: #0077ff;
}
.bannerText .lightblue {
  background-color: #007fff;
  padding: 0 10px;
}
.bannerText a {
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #fff;
  color: #fff;
  font-weight: bold;
  border-radius: 5px;
  background-color: #0077ff;
  padding: 10px 24px;
  transition: 0.5s;
}
.bannerText a:hover {
  color: #007fff;
  background-color: #fff;
  transition: 0.5s;
}

@media screen and (max-width: 600px) {
  .backgroundImage {
    left: 0;
  }
  .bannerText {
    padding-left: 10px;
  }
  .bannerText h1 {
    font-size: 32px;
  }
  .bannerText .bannerDescription {
    margin-top: 50px;
    margin-bottom: 20px;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    color: #fff;
  }
} /*# sourceMappingURL=herobanner.css.map */
