/* Poppin */
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quattrocento:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
.accordianHeader {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  padding: 0 50px;
}

.accordiantext {
  font-family: "Montserrat", serif;
  font-size: 18px;
  font-weight: 700;
  padding: 0 100px;
}

@media screen and (max-width: 600px) {
  .accordianHeader {
    font-size: 18px;
    padding: 0 10px;
  }
  .accordiantext {
    font-size: 14px;
    padding: 0 10px;
  }
}/*# sourceMappingURL=accordian.css.map */