@import "./../variable/variable.scss";

.employee-detail-container {
  background-color: $BackgroudColor;
  width: 100vw;
  height: 100vh;
  // overflow: hidden;
}

.employee-description {
  font-family: $QuattrocentoFont;
  font-size: 24px;
  font-weight: 700;
  color: $LightBlue;
  padding-right: 5px;
}

.employee-header {
  font-family: $QuattrocentoFont;
  font-size: 40px;
  font-weight: 600;
  color: #fff;
  margin: 0;
  padding-left: 20px;
}

.employee-detail-text {
  font-family: $OswaldFont;
  color: white;
  font-size: 18px;
  font-weight: bold;
  line-height: 30px;
}

.employee-input {
  background-color: rgba(5, 226, 255, 0.3);
  padding: 10px 20px;
  border-radius: 5px;
}
