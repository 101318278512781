/* Poppin */
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quattrocento:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

// Font
$OswaldFont: "Montserrat", sans-serif;
$QuattrocentoFont: "Montserrat", serif;
$BackgroudColor: #222222;
$DarkBlue: #0077ff;
$LightBlue: #007fff;

@mixin DarkStyle {
  color: $DarkBlue;
}

@mixin LightStyle {
  background-color: $LightBlue;
  padding: 0 10px;
}

@mixin FontStyle {
  font-family: $OswaldFont;
  font-weight: bold;
  color: #fff;
}

@mixin FontStyleLight {
  font-family: $OswaldFont;
  font-weight: 500;
  color: #fff;
}
