@import "./../../variable/variable.scss";

.footerContainer {
  //   height: 100vh;
  background: linear-gradient(45deg, #565555 0.13%, rgba(34, 34, 34, 0.8) 100%);

  padding: 100px 100px;
}

.footerText h1 {
  @include FontStyle;
  // font-size: 58px;
  font-weight: 600px;
}

.footerText p {
  font-family: $QuattrocentoFont;
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  padding-right: 5px;
}

.phone {
  font-family: $OswaldFont;
  color: $LightBlue;
  text-decoration: none;
  // font-size: 48px;
  font-weight: bold;
}

.email {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footerMap {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

@media screen and (max-width: 600px) {
  .footerContainer {
    padding: 15px 10px;
  }

  .footerText h1 {
    font-size: 24px;
  }

  .footerText p {
    font-size: 18px;
    margin: 0;
  }

  .phone {
    font-size: 24px;
  }

  .icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;

    // background-color: red;
  }

  .contactInfo {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    padding-right: 20px;
  }
}
