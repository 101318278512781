/* Poppin */
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quattrocento:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
.devContainer {
  background-color: #222222;
  padding: 80px 100px 0 100px;
  position: relative;
  z-index: 0;
}
.devContainer p {
  font-family: "Montserrat", serif;
  color: #fff;
  font-size: 24px;
  z-index: 1;
}

.devContainer::before {
  content: "";
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background: linear-gradient(351.48deg, #737373 -1.48%, rgba(34, 34, 34, 0.133333) 100.67%), linear-gradient(270deg, #6a6a6a 0%, rgba(34, 34, 34, 0.13) 100%);
  position: absolute;
  top: 20%;
  left: 80%;
  z-index: -1;
}

.devContainer::after {
  content: "";
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background: linear-gradient(351.48deg, #737373 -1.48%, rgba(34, 34, 34, 0.133333) 100.67%), linear-gradient(270deg, #6a6a6a 0%, rgba(34, 34, 34, 0.13) 100%);
  position: absolute;
  top: 75%;
  right: 80%;
  z-index: -1;
}

.devHeader {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #fff;
  font-size: 64px;
  font-weight: 600;
  z-index: 1;
}

.devImage {
  z-index: 1;
}
.devImage h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #fff;
  color: #0077ff;
  font-size: 28px;
  margin-bottom: 10px;
}
.devImage p {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 28px;
  font-weight: 500;
  margin-top: 0;
}
.devImage p img {
  margin-right: 15px;
}

.devContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}

.lines {
  background-color: red;
  align-items: center;
  z-index: 1;
}

.line {
  justify-content: center;
  align-items: center;
  padding: 0 50px;
  z-index: 1;
}

.circle {
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
  z-index: 1;
}

.sticks {
  width: 500px;
  height: 2px;
  background-color: #fff;
  z-index: 1;
}

.stick {
  width: 100px;
  height: 2px;
  background-color: #fff;
  z-index: 1;
}

@media screen and (max-width: 600px) {
  .devContainer {
    padding: 50px 10px;
  }
  .devContainer p {
    font-size: 18px;
  }
  .devHeader {
    font-size: 32px;
  }
}/*# sourceMappingURL=devProcess.css.map */