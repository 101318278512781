/* Poppin */
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quattrocento:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
.employee-detail-container {
  background-color: #222222;
  width: 100vw;
  height: 100vh;
}

.employee-description {
  font-family: "Montserrat", serif;
  font-size: 24px;
  font-weight: 700;
  color: #007fff;
  padding-right: 5px;
}

.employee-header {
  font-family: "Montserrat", serif;
  font-size: 40px;
  font-weight: 600;
  color: #fff;
  margin: 0;
  padding-left: 20px;
}

.employee-detail-text {
  font-family: "Montserrat", sans-serif;
  color: white;
  font-size: 18px;
  font-weight: bold;
  line-height: 30px;
}

.employee-input {
  background-color: rgba(5, 226, 255, 0.3);
  padding: 10px 20px;
  border-radius: 5px;
}/*# sourceMappingURL=EmployeeDetail.css.map */