@import "./../../../variable/variable.scss";

.accordianHeader {
  @include FontStyle;
  font-size: 22px;
  font-weight: 500;
  padding: 0 50px;
}

.accordiantext {
  font-family: $QuattrocentoFont;
  font-size: 18px;
  font-weight: 700;
  padding: 0 100px;
}

@media screen and (max-width: 600px) {
  .accordianHeader {
    font-size: 18px;
    padding: 0 10px;
  }

  .accordiantext {
    font-size: 14px;
    padding: 0 10px;
  }
}
