body {
  background-color: #222222;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 0.5em;
  background-color: #222222;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #222222;
}

::-webkit-scrollbar-thumb {
  background-color: #0077ff;
  border-radius: 3px;
  outline: 1px solid #ffff;
}
